<template>
  <div class="shareshop">
    <van-nav-bar
      title="店铺二维码"
      left-arrow
      @click-left="onClickLeft"
    />
    <div v-if="false" id="capture" style="overflow: hidden;">
      <div id="qrcode" :style="'padding:' + (screenWidth * 0.1) + 'px;'"></div>
      <van-image
        id="avatarimg"
        crossorigin="anonymous"
        :style="'margin-top:-' + ((screenWidth / 2) + 36) +'px;margin-left:-36px;border: 6px solid #fff; border-radius: 2px;'"
        :src="info && info.logo ? info.logo + '?' + new Date().getTime() : ''"
        fit="cover"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
    </div>
    <div
      v-if="false"
      id="shwo"
      :style="'width:' + screenWidth + 'px;height: ' + screenWidth + 'px;text-align: center;display:none;'"
    >
      <img id="img" crossorigin="anonymous" style="margin: 0 auto;padding: 0;" />
    </div>

    <van-image
      :src="miniprogram"
      :width="screenWidth-20"
      :height="screenWidth-20"
      style="padding: 10px;background: #fff;"
      @click="showTuPian"
      fit="cover"
    >
      <template v-slot:loading>
        <van-loading type="spinner" size="20" />
      </template>
    </van-image>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  name: "Shareshop",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      info: {},
      qrcodeImg: "", // 默认没有二维码
      miniprogram: "", // 小程序码
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getinfo();
    },
    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/user/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.info = res.data.data;
            console.log(that.info);
            // that.showqrcode();
            this.getminprogramCode();
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 获取小程序码
    getminprogramCode() {
      let that = null;
      that = this;
      that.$toast({
        type: "loading",
        message: "加载中...",
        mask: true,
        forbidClick: true,
        duration: 0
      });
      if (that.info.memberid <= 0) {
        that.$toast("未获取商户信息,请重新登录");
        return false;
      }
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "web/goodsposter/getPromoteQr",
          that.$qs.stringify({
            "shareid": that.info.memberid, // 会员id
            "merchid" : that.info.id,
            "page": "pages/index/index", // 二维码地址
            "appid": "wx6a6b5e6fbb588e66"
          })
        )
        .then(res => {
          console.log(res);
          that.$toast.clear();
          if (res.data.code == 100000) {
            that.miniprogram = res.data.data;
            ImagePreview([
              res.data.data
            ]);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    showTuPian() {
      let that = null;
      that = this;
      
      ImagePreview([
        that.miniprogram
      ]);
    },

    showqrcode() {
      let that = null;
      that = this;
      // 二维码链接
      that.qrcodeImg = new that.QRCode(document.getElementById("qrcode"), {
        width: that.screenWidth * 0.8,
        height: that.screenWidth * 0.8,
        text: "https://dk.midbest.cn/?shareid=" + that.info.memberid + "&merchid=" + that.info.id, // 二维码地址
        colorDark: "#000000",
        colorLight: "#ffffff"
      });

      setTimeout(() => {
        const el = document.getElementById("capture"); //要截图的div  document.querySelector("#capture")
        that
          .$html2canvas(el, {
            useCORS: true
          })
          .then(canvas => {
            // 生成图片
            // document.body.appendChild(canvas)
            document.getElementById("capture").style.display = "none";
            document.getElementById("shwo").style.display = "block";
            const tempSrc = canvas.toDataURL("image/png");
            console.log(tempSrc);
            document.getElementById("img").src = tempSrc;
          });
      }, 1000);
    },
    onClickLeft() {
      this.$router.back();
    }
  },
}
</script>
<style lang="less">
.shareshop {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  #capture {
    overflow: hidden;
    position: relative;
    #qrcode {
      overflow: hidden;
      background-color: #fff;
    }
    #avatarimg {
      width: 60px;
      height: 60px;
      position: absolute;
    }
  }

  #shwo {
    overflow: hidden;
    #img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>